import React from "react";
import useResponsiveObserver from "@universal/behaviour/hooks/useResponsiveObserver";
import "./screenSizeMessage.css";

interface ScreenSizeMessageProps {
  message: string;
  minWidth: number;
}

const ScreenSizeMessage: React.FC<ScreenSizeMessageProps> = ({ message, minWidth }) => {

  const isScreenSizeReach = useResponsiveObserver(minWidth);


  if (isScreenSizeReach) return null;

  return (
    <div className="bs-screen-size-message-container">
      <div className="bs-screen-size-marquee">
        <div className="bs-screen-size-message">{message}</div>
      </div>
    </div>
  );
};

export default ScreenSizeMessage;
