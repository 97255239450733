import React, { FunctionComponent }                      from 'react';

import { Switch, Route, Router, WebNavigator, Redirect } from '@cFeatures/router';

import Issues                                            from './views/issues';
import Body                                              from './body';
import Modal                                             from '@common/components/modal';
import { StarterSubscription }                           from '@root/entities/notifications';
import Print                                             from "./features/print";
import Release                                           from '@common/entities/release';
import GeniallyHelper                                    from "@helpers/genially";
import StarterGuide                                      from "./components/starterGuide";
import useHelper                                         from '@uBehaviour/hooks/useHelper';
import useOpenCloseToggle                                from '@uBehaviour/hooks/useOpenCloseToggle';
import Display                                           from '@cComponents/displayIf';
import ScreenSizeMessage                                 from '@root/components/screenSizeMessage';

interface StarterProps {

}

const Root: React.FunctionComponent<{}> = () => (<Redirect to='/issues' />);

const Starter: FunctionComponent<StarterProps> = () => {
  const navigator = React.useMemo(() => new WebNavigator(), []);
  const geniallyHelper = useHelper<GeniallyHelper>("genially");
  const [displayStarterGuide, openStarterGuide, closeStarterGuide] = useOpenCloseToggle();

  React.useEffect(() => {
    navigator.initialize();

    geniallyHelper.wasSeenByUser("660bf9b3d4f85e0014d900fc").then((result: boolean) => {
      if(!result) {
        openStarterGuide();
      }
    });

    return () => {
      navigator.dipose();
    };
  }, []);

  return (
    <Body>
      <ScreenSizeMessage  message="résolution non optimisée pour votre taille d'écran" minWidth={360} />
      <Router navigator={ navigator }>
        <Switch>
          <Route path='/issues' component={ Issues.List } />
          <Route path=''        component={ Root } />
        </Switch>
      </Router>
      <Modal.UrlRequest displayModalValue="notificationSettings" style={{ maxWidth: "800px", maxHeight: "700px", width: "90vw", height: "90vh", padding: "20px" }}>
      { (close) => ( <StarterSubscription close={ close } /> )}
      </Modal.UrlRequest>
      <Release.Next />
      <Release.AutoList application="starter" />
      <Display.If condition={ displayStarterGuide }>
        <StarterGuide close={ closeStarterGuide }/> 
      </Display.If> 
    </Body>
  );
}

const PreApplication: FunctionComponent<StarterProps> = () => (
  <Switch>
    <Route path="/print" component={ Print }/>
    <Route path="" component={ Starter } />
  </Switch>
);

export default PreApplication;
