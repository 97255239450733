export default {
  "fr": "FR",
  "nl": "NL",
  "en": "EN",
  "welcome": "Bienvenue",
  "logout": "Déconnexion",
  "login": "Se connecter",
  "day": "Jour",
  "days": "Jours",
  "five_day": "5 jours",
  "month": "Mois",
  "password": "Mot de passe",
  "login_welcome": "Mot de passe oublié: veuillez contacter",
  "login_error_password": "L'email ou le mot de passe introduit n'est pas valide",
  "login_error_readOnlyOnApp": "Votre profil en lecture seule ne vous permet pas actuellement d'utiliser cette application.",
  "language": "Langue",
  "home": "Accueil",
  "by_anonymous": "Anonyme",
  "submit": "Publié",
  "signs": "Signalisation",
  "festivity": "Festivité",
  "water": "Raccordement d'eau",
  "tagged_as": "Marqué",
  "tree": "Arbre - Espace vert",
  "playground": "Terrain de jeu",
  "dripping": "Avaloir - Egout",
  "waste": "Collecte déchets",
  "flood": "Inondation",
  "deposit": "Dépot clandestin",
  "lighting": "Eclairage public",
  "furniture": "Mobilier urbain",
  "pothole": "Nid de poule",
  "pollution": "Pollution",
  "road": "Route - Trottoir",
  "grafitti": "Graffiti - Vandalisme",
  "suggestion": "Suggestion",
  "congratulation": "Bravo !",
  "all_states": "Tous",
  "test": "test",
  "customers": "Clients",
  "archived": "Archivé",
  "issueArchived": "Archivée",
  "issueUnArchived": "Désarchivée",
  "open": "Ouvert",
  "resolved": "Résolu",
  "closed": "Fermé",
  "in_progress": "En traitement",
  "planned": "Planifié",
  "bsId_placeholder": "Chercher id...",
  "deleted": "Supprimé",
  "rejected": "Rejeté",
  "transferred": "Transféré",
  "nav_new_issue": "Nouvelle entrée",
  "nav_dashboard": "Home",
  "nav_all_issues": "Toutes les entrées",
  "nav_admin_communes": "Admin",
  "select_all": "Toutes",
  "unselect_all": "Aucune",
  "unselect_all_f": "Aucune",
  "sort_by": "Trier par",
  "states": "Statut",
  "reset_filters": "Tout sélectionner",
  "load_filters": "Mes filtres par défaut",
  "save_filters": "Enregistrer les filtres actuels comme défaut",
  "categorys": "Catégories",
  "request_details": "Request details",
  "filter_no_result": "Aucune entrée trouvée...",
  "form_validation_name": "Minimum deux caractères requis",
  "form_validation_select_tag": "Merci de choisir une catégorie",
  "form_validation_select_image": "Merci de choisir une photo",
  "form_validation_accepted_validimage": "Merci de fournir un format .jpg, .jpeg ou .png uniquement",
  "form_validation_size_validsizeimage": "Merci de fournir une photo d'une taille maximale de 5 MB",
  "form_validation_add_comment": "Merci de décrire brièvement votre suggestion",
  "form_validation_add_location": "Merci d'indiquer la localisation de votre photo",
  "form_location": "Localisez votre photo",
  "form_location_legend": "Indiquez l’adresse ci-dessous OU déplacez le pointeur sur la carte",
  "form_photo": "Importez votre photo",
  "add_picture": "Ajouter une photo",
  "form_photo_legend": "Une seule photo, maximum 5 MB",
  "form_tag": "Choisissez une catégorie",
  "form_tag_title": "Catégorie",
  "form_comment": "Votre commentaire",
  "form_comment_legend_optional": "Maximum 160 caractères",
  "sub_building_max": "Maximum 60 caractères",
  "form_comment_legend_mandatory": "Maximum 160 caractères",
  "form_anonymous_legend": "Votre nom n'apparaitra pas sur le site public mais sera visible pour votre administration communale",
  "form_submit": "Envoyer",
  "alert_cant_be_public": "Une entrée sans photo ne peut être publique",
  "where": "Où?",
  "updateIssuePlanification": "Assigné à",
  "updateIssueOrderAndAssign": "Assigné à",
  "newCommentFromCitizen": "Nouveau message d'un citoyen",
  "updateIssueOrderAndAssignAndScheduled": "Assigné à",
  "updateIssueAssignAndScheduled": "Assigné à",
  "updateIssueOrder": "Consignes modifiées",
  "updateIssueState": "Statut modifié",
  "memo_added": "Note / Message",
  "addIssueInternalNote": "Note interne",
  "update_issue_picture": "Photo ajoutée",
  "updateIssuePicture": "Photo ajoutée",
  "createIssue": "Nouvelle entrée",
  "updated_issue_to": "Statut modifié",
  "updateIssueStateMergin": "Statut modifié",
  "createFacebookUser": "Création d'un user via Facebook",
  "createUserPro": "Création d'un user pro",
  "updateIssueRequestor": "Demandeur modifié",
  "updateIssueDisplayState": "Visibilité modifiée",
  "emailSent": "Email au demandeur",
  "phone": "Téléphone",
  "email": "Email",
  "letter": "Courrier",
  "citizen": "Citoyen",
  "internal": "Interne",
  "local_stuff": "Interne",
  "localitys": "Localités",
  "update_status": "update_status",
  "other": "Autre",
  "steward": "Steward",
  "administrative": "Administratif",
  "wasteCollectionTruck": "Service propreté",
  "worker": "Ouvrier communal",
  "police": "Police",
  "request_description": "Description...",
  "requestor_details": "Informations demandeur",
  "form_validation_location_unauthorizedzip": "Merci d'indiquer une adresse sur votre territoire.",
  "form_validation_add_email": "Merci de renseigner un email valide.",
  "form_validation_location_unselectedzip": "Veuillez entrer un numéro de code postal dans l'adresse",
  "form_validation_location_unauthorizedAddress": "L'adresse doit contenir au minium un code postal ou une localite ainsi qu'un nom de rue",
  "form_validation_location_requiredAddress": "Merci d'entrer une adresse",
  "all_town": "all_town",
  "issue_resolved_email_management_template": "L'email suivant va être envoyé au demandeur",
  "issue_deleted_email_management_template": "issue_deleted_email_management_template",
  "issue_resolved_no_email": "Le citoyen a indiqué dans ses préférences ne pas vouloir recevoir d'email",
  "confirmation_email_send": "Votre email a bien été envoyé",
  "created_at": "Créé",
  "audience": "Public",
  "private": "Privé",
  "public": "Public",
  "memo": "Note / Message",
  "cancel": "Annuler",
  "firstname": "Prénom",
  "tel": "Tel.",
  "lastname": "Nom",
  "service": "Profil",
  "device": "Origine",
  "comment": "comment",
  "internal_comment": "Commentaire interne",
  "issue_source": "Demandeur",
  "citizen_internal": "Citoyen / Interne",
  "issue_details": "issue_details",
  "type": "Type",
  "location": "location",
  "sender": "sender",
  "creation": "creation",
  "updated": "updated",
  "status": "Statuts",
  "status_sing": "Statut",
  "creation_date": "Date de création",
  "creation_date_ascending": "Date de création croissante",
  "creation_date_descending": "Date de création décroissante",
  "update_date_ascending": "Date de modification croissante",
  "update_date_descending": "Date de modification décroissante",
  "issues": "Entrées",
  "from": "De",
  "to": "A",
  "print": "Imprimer",
  "print_export": "Imprimer/Exporter",
  "title_activity_logs": "Activités",
  "meta_desc": "meta_desc",
  "image": "image",
  "login_error_unAuthorized": "L'email ou le mot de passe introduit n'est pas valide",
  "last_updated": "Mis à jour",
  "edit_picture": "edit_picture",
  "click_here_to_edit": "Editer",
  "click_here_to_cancel": "Cliquez ici pour annuler",
  "informations": "Informations",
  "requestor": "Demandeur",
  "via": "via",
  "clear": "Annuler",
  "source": "Source",
  "contact": "Contact",
  "name": "Nom",
  "confirm_update": "Sauver",
  "save_changes": "Sauver",
  "address": "Adresse",
  "form_validation_leavepage": "Certaines modifications n'ont pas été enregristrées.",
  "choose": "Choisir",
  "locality": "locality",
  "bsId": "bsId",
  "visibility": "Visibilité",
  "function": "Fonction",
  "communicate_via": "Signalez via",
  "communicate_by": "Signalé via",
  "mail_object": "Sujet",
  "mail_message": "Message",
  "mail_your_comment": "Commentaire optionnel",
  "password_verification": "Vérification mot de passe",
  "form_validation_password": "Mot de passe de vérification incorrect",
  "password_validation": "Merci de définir un mot de passe (à introduire deux fois)",
  "form_validation_passwordregexp": "Mot de passe non valide (Minimum 6 caractères alphanumériques)",
  "updateIssueTag": "Catégorie modifiée",
  "updateIssueETag": "Catégorie modifiée",
  "updated_issue_tag_to": "Catégorie modifiée",
  "send": "Message interne",
  "send_issue_by_email": "Message interne",
  "choose_a_colleague": "Choisir un destinataire",
  "add": "Ajouter",
  "transfer": "Transférer",
  "transfer_label": "Transféré à",
  "issueTransfered": "Transféré à",
  "choose_a_transfer": "Choisir un destinataire",
  "transfer_issue_by_email": "Transfert externe",
  "issue_sent": "Votre message interne a été envoyé.",
  "issue_transfered": "Votre message a été envoyé.",
  "issue_sent_to": "Message interne à",
  "issueSent": "Message interne à",
  "issue_transfered_to": "Transféré à",
  "form_validation_requiredValue": "Champ obligatoire",
  "issue_update_message": "issue_update_message",
  "unselect": "Aucun",
  "transfer_none": "Non transféré",
  "assign_none": "Non assigné",
  "nav_all_issues_list": "Liste",
  "nav_all_issues_map": "Carte",
  "nav_all_issues_little_list": "Liste compacte",
  "owner": "Gestionnaire",
  "priority": "Priorité",
  "updated_issue_new_priority": "Priorité modifiée",
  "updateIssuePriority": "Priorité modifiée",
  "owners": "Gestionnaires",
  "priorities": "Priorités",
  "priority_ascending": "Priorité croissante",
  "priority_descending": "Priorité décroissante",
  "a_low": "Normal",
  "j_middle": "Moyen",
  "z_high": "Urgent",
  "stats_states_by_tags": "Statistiques statuts par catégorie",
  "numbers": "Nombres",
  "update_owner": "Gestionnaire modifié",
  "updateIssueOwner": "Gestionnaire modifié",
  "change_owner_to_me": "Vous allez être le nouveau gestionnaire",
  "you_are_not_owner": "Oops. Vous n'avez pas la gestion de ce dossier.",
  "you_are_not_ower_message": "Etes-vous sur de vouloir continuer?",
  "updated_issue_new_address": "Adresse modifiée",
  "updateIssueAddress": "Adresse modifiée",
  "stats_states_pie": "Répartition des statuts",
  "scheduled_to": "au",
  "remove_issue_transfer": "Vous confirmez la modification de transfert?",
  "issue_transfer_deleted": "Transfert supprimé",
  "removeTransfer": "Transfert supprimé",
  "assign": "Assigné à",
  "updated_issue_scheduled": "Date de début modifiée",
  "updateIssueScheduled": "Date de début modifiée",
  "deleteIssueScheduled": "Date prévue supprimée",
  "transfers": "Transféré à",
  "without": "Aucun",
  "issue_assign": "Assignation",
  "issue_assigned_to": "Equipe & Agents",
  "issueAssigned": "Assigné à",
  "issue_assigned_deleted": "Assignation supprimée",
  "removeAssign": "Assignation supprimée",
  "remove_issue_assign": "Vous confirmez la modification d'assignation?",
  "assigns": "Equipe & Agents",
  "update_issue_address": "Changer la localisation",
  "Ok": "Ok",
  "reset_password": "Réinitialiser votre mot de passe",
  "reset_password_unknow_email": "Email inconnu ou pseudo. Merci de prendre contact avec votre administrateur.",
  "reset_password_email_sent": "Un email vous a été envoyé pour réinitialiser votre mot de passe",
  "reset_password_incorrect_token": "Ce lien n'est plus valide... Veuillez effectuer une nouvelle demande et recommencer.",
  "new_email_validation_sent": "Un nouvel email de validation de votre compte vous a été envoyé.",
  "new_email_validation_unknow_email": "Email inconnu",
  "new_email_validation_user_already_validated": "Votre compte utilisateur a déjà été précedemment validé.",
  "reset_password_no_email": "Email manquant",
  "password_forgotten": "Mot de passe oublié?",
  "assign_to": "Equipe",
  "reset_password_ok": "Modification de mot de passe effectuée avec succès.",
  "stats_issue_in_time": "Nouvelles entrées par jour",
  "public_space": "Espace public",
  "building": "Bâtiment",
  "select_issue_type": "Type de signalement",
  "form_building": "Choisir un bâtiment",
  "electricity": "Electricité / Eclairage",
  "plumbing": "Plomberie / Sanitaire",
  "heating": "Chauffage",
  "roof": "Toit",
  "painting": "Peinture / Murs",
  "furnitures": "Mobilier",
  "miscellaneous": "Divers",
  "doors": "Portes / Fenêtres",
  "external_infrastructure": "Infrastructures extérieures",
  "buildings": "Bâtiments",
  "buildings_tags": "Catégories bâtiments",
  "contact_issue_owner": "Ecrire au gestionnaire",
  "owner_contacted": "Message du demandeur",
  "contactIssueOwner": "Message au gestionnaire",
  "requestor_contacted": "Email au demandeur",
  "contactIssueRequestor": "Email au demandeur",
  "contact_issue_requestor": "Envoyer email au demandeur",
  "internal_building_profile": "Interne bâtiments",
  "issue_update_state_required_comment": "Merci d'indiquer la raison du rejet ou de la suppression",
  "form_validation_requiredBuilding": "Merci de sélectionner un bâtiment",
  "alert_building_cant_be_public": "Les demandes bâtiments sont toujours privées",
  "update_issue_building": "Choisir un autre bâtiment",
  "updated_issue_new_building": "Bâtiment modifié",
  "updateIssueBuilding": "Bâtiment modifié",
  "contact_requestor": "Email au demandeur",
  "download_pdf": "download_pdf",
  "description_updated": "Description modifiée",
  "updateIssueDescription": "Description modifiée",
  "comment_updated": "Message public modifié",
  "updateIssueComment": "Message public modifié",
  "new_comment": "Message public",
  "addIssueComment": "Message public ajouté",
  "description": "Description",
  "describe_issue": "Décrire la demande",
  "add_desc": "Ajouter une description",
  "add_order": "Ajouter des consignes",
  "show_more": "Voir plus",
  "show_less": "Voir moins",
  "scheduled": "Date planifiée",
  "scheduled_ascending": "Date planifiée croissante",
  "scheduled_descending": "Date planifiée décroissante",
  "scheduledTo_ascending": "Date d'échéance croissante",
  "scheduledTo_descending": "Date d'échéance décroissante",
  "work_order": "Bon de travail",
  "instructions": "Consignes",
  "ended": "Terminé le",
  "remarks": "Remarques",
  "signature": "Signature",
  "print_all": " Imprimer écran",
  "by": "Par",
  "create_at": "Créée le",
  "direct_contact": "Interpellation directe",
  "contact_support": "Un souci? Contactez-nous",
  "nav_stats": "Statistiques",
  "no_email_will_be_sent": "Confirmation changement de statut",
  "save_filter_title": "Sauvegarde des filtres",
  "save_filter_explain": "<p>Les filtres sélectionnés actuellement vont être enregistrés comme étant vos nouveaux filtres par défaut.</p><p> Pour appliquer ces filtres sur la liste ou la carte, il vous suffit de cliquer sur l'icône <i class='icon-filter' style='font-size: 15px;'></i>.</p> <p>Vous pouvez à tout moment ré-enregistrer de nouveaux filtres pour les modifier.</p>",
  "delete_user_filters": "delete_user_filters",
  "change_issue_state_and_make_it_public": "Envoyer et rendre le signalement public",
  "save_and_make_it_public": "Sauver et rendre le signalement public",
  "save_filter_error_no_empty_filter": "Afin de sauvegarder vos filtres, merci de sélectionner au minimum un élément pour les filtres suivants",
  "load_no_filter_title": "Ooops",
  "load_no_filter_explain": "Vous n’avez pas encore défini de filtres par défaut",
  "refresh_filters": "Rafraîchir",
  "Ooops": "Ooops",
  "filters": "Filtres",
  "country": "Pays",
  "belgium": "Belgique",
  "france": "France",
  "communes": "Communes",
  "email_reports_title": "Rapport d’activités",
  "email_reports_count_title": "email_reports_count_title",
  "email_reports_open_issues": "signalement(s) ouvert(s) depuis plus de 15 jours",
  "email_reports_in_progress_issues": "signalement(s) en traitement depuis plus de 15 jours",
  "email_reports_planned_issues": "signalement(s) planifié(s) dont la date prévue est dépassée",
  "connect_to_betterstreet": "Se connecter à BetterStreet Admin",
  "date_from_title": "Résumé des signalements du",
  "date_from": "Du",
  "date_to": "Au",
  "last_week_created": "Nouveau(x)",
  "last_week_updated": "Mis à jour",
  "last_week_resolved": "Résolu(s)",
  "week": "Semaine",
  "reports_unsubscribe": "Si vous ne souhaitez plus recevoir ce rapport, merci de l’indiquer à",
  "search": "Chercher",
  "publicSpace": "Espace public",
  "tag": "Catégorie",
  "zip_code": "Code postal",
  "me": "Moi",
  "unauthorized_locality": "Votre géolocalisation est hors du territoire de votre commune. Vous n'avez pas la possibilité de créer un signalement à cet endroit...",
  "login_error_validatePassword": "Merci de choisir et confirmer un nouveau mot de passe...",
  "requestor_updated": "Demandeur modifié",
  "export": "Exporter",
  "filtered_list": "Liste filtrée",
  "export_all": "Tout",
  "other_local_stuff": "Autre interne",
  "no_result": "Aucun résultat",
  "initial_comment_updated": "Description initiale du demandeur modifiée",
  "updateIssueInitialComment": "Description initiale du demandeur modifiée",
  "delete_issue_comment": "Message public supprimé",
  "deleteIssueComment": "Message public supprimé",
  "warning_comment_is_public": "Cette description sera visible pour un signalement rendu public.",
  "comment_is_public": "Rendre ce commentaire public et visible de tout citoyen",
  "public_comment": "Ce commentaire sera public et visible de tout citoyen.",
  "public_comment_title": "Messages publics",
  "add_public_comment": "Ajouter un message public",
  "cant_be_public": "Ce message ne peut être public que si le signalement l'est également. Veuillez désélectionner ou cliquer sur",
  "remove_comment": "Supprimer le message",
  "confirmation_remove_comment": "Vous allez supprimer le message public suivant",
  "remove": "Supprimer",
  "issue_update_state_required_display": "Disponible uniquement si le signalement est public.",
  "log_comment_public": "Nouveau commentaire public",
  "issue_update_state_required_public_comment": "Merci de rédiger un message (ou de désélectionner ci-dessous).",
  "dashboard": "Paramètres généraux",
  "subscription_type": "Type d'abonnement",
  "default_visibility": "Visibilité par défaut",
  "default_display_on": "Signalements publics",
  "default_display_off": "Signalements privés",
  "default_display_explanation": "Visibilité par défaut des signalements de citoyens",
  "public_site_url": "URLs des pages de la commune",
  "add_user": "Ajouter utilisateur",
  "reply_email": "Email de notification / communication",
  "fullname": "Nom complet",
  "display_name": "Signature des emails hors commune",
  "email_notification": "Email lors de l'arrivée d'un nouveau signalement",
  "reports": "Reception rapports hebdomadaires",
  "email_notification_table": "Notification email",
  "reports_table": "Rapport hebdo.",
  "edit": "Editer",
  "profile": "Profil utilisateur",
  "building_profile": "Bâtiment",
  "public_space_profile": "Espace Public",
  "full_profile": "Complet",
  "read_only_profile": "Limité (ajouter/consulter)",
  "form_title_user_creation": "Nouvel utilisateur",
  "form_title_user_edit": "Modification utilisateur",
  "form_title_user_edit_password": "Réinitialisation de mot de passe",
  "select_a_profile": "Choisir un profil",
  "user_pro_email_notifications_explanation": "Uniquement pour profil 'Complet' gestionnaire de catégorie",
  "user_pro_reports_explanation": "Uniquement pour profil 'Complet'",
  "add_transfer": "Ajouter transfert",
  "add_assign": "Ajouter équipe & agents",
  "form_title_transfer_creation": "Nouveau transfert",
  "form_title_transfer_edit": "Modification de transfert",
  "form_title_assign_creation": "Nouvelle assignation",
  "form_title_assign_edit": "Modification d'assignation",
  "add_building": "Ajouter bâtiment",
  "form_title_building_creation": "Nouveau bâtiment",
  "form_title_building_edit": "Modification bâtiment",
  "admin_nav_options": "Paramètres",
  "admin_nav_owners": "Gestionnaires",
  "admin_nav_users": "Utilisateurs",
  "admin_nav_assignees": "Assignations",
  "admin_nav_transfers": "Transferts",
  "admin_nav_buildings": "Bâtiments",
  "admin_nav_emails": "Emails",
  "admin": "Console d'administration",
  "admin_array_title_transfers": "Transferts",
  "admin_array_title_assignees": "Assignations",
  "admin_array_title_buildings": "Bâtiments",
  "admin_array_title_emails": "Emails",
  "admin_array_title_owners": "Gestionnaires",
  "admin_array_title_users": "Utilisateurs",
  "error": "Cet email est déjà utilisé sur un utilisateur existant",
  "please_try_again": "Merci de renseigner une autre adresse email pour cet utilisateur",
  "email_already_in_use": "L'email renseigné ne peut être utilisé car déjà lié à un autre utilisateur",
  "first_name": "Prénom",
  "last_name": "Nom",
  "options": "Options",
  "user": "Utilisateur",
  "form_title_owner_edit": "Modification gestionnaire",
  "modification_success": "Modification(s) sauvegardée(s)",
  "form_validation_userIsOwner": "Cet utilisateur est actuellement gestionnaire de catégorie(s). Afin de pouvoir modifier son profil, veuillez au préalable sélectionner un autre utilisateur comme gestionnaire.",
  "add_pictures": "Ajouter photo(s) ou PDF(s)",
  "or": "ou",
  "drag_your_file": "Glisser et déposer photo(s) ou PDF(s) ici",
  "issue_cover": "Photo principale",
  "confirm_update_cover": "Utiliser comme photo principale ?",
  "confirm_delete_picture": "Supprimer cette photo ?",
  "update_cover_modal_title": "Utiliser comme photo principale ?",
  "issue_picture_added": "Photo(s) ajoutée(s)",
  "addIssuePicture": "Photo(s) ajoutée(s)",
  "issue_picture_deleted": "Photo supprimée",
  "deleteIssuePicture": "Photo supprimée",
  "deleteIssuePDF": "PDF supprimé",
  "app_loading": "Chargement en cours...",
  "charts_loading": "Chargement en cours...",
  "without_filters": "entrées trouvées sans tenir compte de vos filtres. Cliquer ici pour les visualiser.",
  "existing_building_name": "Ce nom de bâtiment est déjà utilisé. Merci de renseigner un nom différent.",
  "admin_nav_widget": "Widgets",
  "width": "Largeur",
  "add_widget": "Ajouter un widget",
  "form_title_widget_creation": "Widgets",
  "widget_issues_name": "Nom du widget",
  "widget_issues_title": "Titre sur le widget",
  "widget_display_issues": "Afficher signalements",
  "widget_display_only_resolved": "Uniquement les résolus",
  "widget_display_add_issue_button": "Afficher bouton",
  "widget_button_label": "Libellé du bouton",
  "widget_display_banner": "Afficher bannière",
  "widget_width": "Largeur du widget (pixels)",
  "widget_issues_number": "Nombre de signalements",
  "404_info": "La page demandée n'existe pas ou une erreur inattendue s'est produite...",
  "choose_receiver": "Sélectionnez un destinataire",
  "user_didnt_accept_notification": "Aucun email ne sera envoyé car l’utilisateur ne souhaite pas être notifié par email",
  "citizen_email_unknown": "Aucun email ne sera envoyé car l’email du demandeur est inconnu",
  "internal_issue": "Aucune email ne sera envoyé car c’est une demande interne",
  "alert_entity_issue_cant_be_public": "Demandes de ce type toujours privées",
  "error_ie_title": "Merci de mettre à jour votre navigateur pour utiliser BetterStreet",
  "error_ie_contact_us": "Vous rencontrez un souci ? Contactez-nous sur",
  "error_ie_explain_1": "Nous avons construit BetterStreet à l’aide des dernières technologies afin que le service soit rapide, efficace et facile à utiliser. Malheureusement, votre navigateur ne supporte pas ces technologies.",
  "error_ie_explain_2": "Aucun soucis à vous faire, il vous suffit de télécharger gratuitement un de ces navigateurs modernes pour pouvoir en profiter.",
  "404_back": "Retour à la liste des signalements",
  "instructions_list": "Bons de travail - Plusieurs par page",
  "print_work_order": "Imprimer bon de travail",
  "print_work_order_one": "Imprimer bon de travail - Un par page",
  "print_work_order_multi": "Imprimer bon de travail - Plusieurs par page",
  "create_recurrence": "Créer une récurrence",
  "goTo_recurrence": "Aller à la récurrence",
  "delete_date": "delete_date",
  "owner_notified": "Le gestionnaire a été notifié",
  "admin_nav_tags": "Catégories",
  "admin_array_title_tags": "Catégories",
  "add_tag": "Ajouter catégorie",
  "bs_tag": "Catégorie par défaut",
  "tag_public_explanation": "Catégorie visible des citoyens",
  "tag_active_explanation": "Catégorie active",
  "last_connection": "Dernière connexion",
  "reason_no_email_will_be_sent": "Aucun email ne sera envoyé pour ce changement de statut",
  "new_nears_building": "Déjà signalé dans ce bâtiment...",
  "nears_building": "Dans ce bâtiment...",
  "new_nears_public_space": "Déjà signalé à proximité...",
  "nears_public_space": "A proximité...",
  "today": "Aujourd'hui",
  "seven_last_days": "7 derniers jours",
  "thirteen_last_days": "30 derniers jours",
  "custom_dates": "Personnalisé",
  "tomorrow": "Demain",
  "seven_next_days": "7 prochains jours",
  "following_email_will_be_sent": "L'email suivant va être envoyé au demandeur et à ses suiveurs éventuels",
  "deleteIssueScheduledTo": "Date planifiée supprimée",
  "updateIssueScheduledTo": "Date de fin modifiée",
  "deadline": "Echéance",
  "no_email": "Pas d'email",
  "scheduled_from_validation": "Oops. Pour planifier, une équipe doit être assignée!",
  "reload_alert": "L'application Betterstreet a été mise à jour depuis votre dernière utilisation. Un rechargement de page est nécessaire pour intégrer les modifications.",
  "plz_select_team": "Merci de sélectionner une équipe ou un ouvrier",
  "filter_only_planned": "Sans dates planifiées",
  "show_list": "Voir liste",
  "show_filters": "Voir filtres",
  "list": "Liste",
  "calendar_loading": "Chargement du calendrier...",
  "calendar": "Calendrier",
  "warning": "Attention",
  "issue_over_territory": "L'adresse renseignée est située hors de votre territoire...",
  "issue_over_territory_update": "Attention: l'adresse renseignée est située hors de votre territoire...",
  "update_state_title_1": "Le statut du signalement",
  "update_state_title_2": "a été mis à jour.",
  "update_state_explain": "En fonction des filtres sélectionnés, le signalement peut ne plus apparaître dans cette liste.",
  "internal_user": "Utilisateur interne",
  "admin_array_title_sub_buildings": "Sous-bâtiments",
  "existing_place_name": "Cette dénomination de sous-bâtiment existe déjà.",
  "form_sub_building": "Localisation précise...",
  "form_sub_building_validation": "Merci de préciser la localisation",
  "add_sub_building": "Ajouter sous-bâtiment",
  "edit_sub_building": "Modifier sous-bâtiment",
  "reload_user_alert": "Votre profil utilisateur a été modifié par un administrateur. Votre fenêtre de navigateur va être rafraichie pour refléter ces changements...",
  "recurrence_settings": "Paramètres de récurrence",
  "recurrence": "Récurrence",
  "weekly": "Hebdomadaire",
  "monthly": "Mensuelle",
  "annual": "Annuelle",
  "repeat_every": "Répéter tous les",
  "repeat_every_f": "Répéter toutes les",
  "year_the": "an(s) le",
  "month_the": "mois le",
  "day_the": "jour(s) le",
  "recurrence_end": "Fin",
  "no_end_date": "Pas de date de fin",
  "end_after": "Finie après",
  "ocurrences": "occurence(s)",
  "create_next_issue": "Créer l'intervention suivante",
  "day_before_recurrence": "jours avant la date d'échéance calculée",
  "based_on_parameters": "Sur base de ces paramètres",
  "first_occurence_at": "La première occurence aura comme date d'échéance le",
  "next_intervention_will_be": "L’intervention suivante de la série sera créée automatiquement le",
  "delete_recurrence": "Supprimer la récurrence",
  "modify_recurrence": "Modification de la récurrence",
  "weekly_recurrence": "Récurrence hebdomadaire",
  "monthly_recurrence": "Récurrence mensuelle",
  "annual_recurrence": "Récurrence annuelle",
  "recurrenceCreated": "Récurrence ajoutée",
  "updated_recurrence": "Récurrence modifiée",
  "updateRecurrence": "Récurrence modifiée",
  "deleteIssueRecurrence": "Récurrence supprimée",
  "adapt_update_to_recurrence": "Souhaitez-vous que ce changement s'applique également aux futures interventions récurrentes qui seront crééent?",
  "week_the": "semaine(s) le",
  "pdf_documents": "Document(s) PDF",
  "show": "Voir",
  "new_recurrence": "Récurrence ajoutée",
  "print_work_order_without_pdf": "Bon de travail uniquement",
  "print_work_order_with_pdf": "Bon de travail et document(s) PDF",
  "addIssuePDF": "PDF ajouté",
  "addIssuePictureAndPDF": "Photo(s) et PDF(s) ajouté(s)",
  "confirmation": "confirmation",
  "currentIssue.description": "currentIssue.description",
  "ail_object": "ail_object",
  "new_issue_submit_error": "Veuillez corriger les erreurs  indiquées ci dessus... ",
  "delete_pdf_title": "Supprimer PDF",
  "delete_pdf_confirmation": "Êtes-vous certain de vouloir supprimer ce PDF?",
  "of_date": "de",
  "create_the": "Création le",
  "end_by": "Fin",
  "confirmation_delete_recurrence": "Êtes-vous certain de vouloir supprimer cette récurrence?",
  "this_issue": "Ce signalement",
  "localisation": "Localisation",
  "all_the": "Tous les",
  "all_the_f": "Toutes les",
  "weeks_the": "semaines le",
  "update_recurrence_template": "Ce signalement est récurrent. Souhaitez-vous appliquer cette modification aux futurs signalements qui seront créés automatiquement via cette récurrence?",
  "yes": "Oui",
  "no": "Non",
  "update_recurrence_title": "Adapter la récurrence?",
  "createIssueByRecurrence": "Nouvelle entrée (via recurrence)",
  "recurrence_issues": "Signalements de cette récurrence",
  "recurrence_finished": "Série terminée",
  "recurrence_error_create_interval": "Nombre de jours incorrect",
  "recurrence_error_limit": "Nombre d'occurences insuffisant",
  "disable_user_pro": "Désactiver",
  "disable_user_pro_modal_title": "Désactivation utilisateur",
  "reactive_user_pro": "Réactiver",
  "disable_user_pro_confirmation": "L'utilisateur <%= name %> va être désactivé. Il n'aura plus accès à l'application et n'apparaitra plus dans celle-ci.",
  "disable_user_pro_error_owner": "Cet utilisateur ne peut être désactiver aussi longtemps qu'il est gestionnaire de catégorie.",
  "disable_user_pro_error_owner_link": "Veuillez sélectionner un autre gestionnaire pour les catégories concernées en cliquant ici.",
  "show_owner_tags": "Modifier gestionnaires",
  "for_example": "par exemple",
  "by_email": "Envoyer un message",
  "save_and_notif_user": "Sauver et inviter l'utilisateur par email",
  "new_account_notification": "Invitation du nouvel utilisateur",
  "send_user_activation": "Inviter utilisateur par email",
  "help_me": "Contacter le support",
  "help": "Aide",
  "choose_password": "Choisissez votre mot de passe",
  "set_new_password": "Choisissez un nouveau mot de passe",
  "active_account_email_title": "Bienvenue sur BetterStreet Admin",
  "active_account_email_intro_1": "Vous recevez cet email car un accès à BetterStreet vient de vous être attribué",
  "active_account_email_intro_2": "BetterStreet est le nouvel outil de gestion de l'espace public et des bâtiments communaux de la commune. Il a pour objectif principal de centraliser toutes les tâches les demandes et interventions du service travaux en un endroit unique ainsi que d'offrir une nouvelle manière de communiquer avec les citoyens. ",
  "active_account_email_call_to_action": "Activez votre utilisateur BetterStreet en cliquant ci-dessous (Il vous sera demandé de choisir un mot de passe afin de sécuriser votre accès)",
  "active_account_email_btn": "Activer votre compte maintenant en cliquant ici ",
  "active_account_email_contact": "Vous avez une question? Contactez",
  "session_expired": "Votre session utilisateur a expiré. Merci de bien vouloir vous connecter à nouveau.",
  "stats_created_issues": "Nouveaux",
  "stats_resolved_issues": "Résolus",
  "stats_time_to_resolve": "Durée de résolution",
  "stats_not_resolved_since_50_days": "Non résolus > 2 mois",
  "stats_issues_by_date": "Nouvelles interventions",
  "stats_status_pie": "Répartition des états d’avancement",
  "stats_espace_public_vs_building": "Esp. public / Bât. / Equip. ",
  "stats_espace_public_vs_building_short": "Esp. pub. vs Bât.",
  "stats_citizen_vs_internal": "Citoyens vs Internes",
  "stats_tags_bar": "Espace public - Catégories les plus utilisées",
  "stats_building_tags_bar": "Bâtiments - Catégories les plus utilisées",
  "stats_citizens_bar": "Citoyens les plus actifs",
  "stats_buildings_bar": "Bâtiments les plus actifs",
  "stats_web_vs_mobile": "Web vs Smartphone",
  "this_week": "Semaine en cours",
  "last_week": "La semaine dernière",
  "this_month": "Mois en cours",
  "last_month": "Mois dernier",
  "last_three_months": "Les 3 derniers mois",
  "last_six_months": "Les 6 derniers mois",
  "stats-dates": "Période d’analyse",
  "stats_no_data": "Pas de données",
  "pie_show_less": "Voir tous les signalements",
  "pie_show_more": "Voir uniquements les signalements pertinents",
  "all_time": "Toute la période",
  "monthly_reports": "Réception statistiques mensuelles",
  "stats_email_call_actions": "Voir le tableau de bord complet",
  "stats_email_subject": "Tableau de bord <%= month%> <%= year %>: <%= numbers %> interventions réalisées",
  "stats_email_title": "Tableau de bord <%= month%> <%= year %>",
  "monthly_reports_table": "Stats mensuelles",
  "confirm_picture_rotation": "Sauver la rotation de l'image ?",
  "set_issue_cover": "Définir comme photo principale",
  "rotate_picture": "Rotation photo",
  "link_to_citizen_app": "Connexion pour les citoyens",
  "incorrect_geolocation_datas": "Les paramètres de localisations requis sont manquants.",
  "admin_resource_client": "Client ?",
  "admin_resource_building_management": "Bâtiments ?",
  "user_pro_monthly_reports_explanation": "Réception du rapport mensuel par email",
  "admin_resource_default_display": "Signalements publics par défaut ?",
  "admin_resource_private": "BetterStreet PRIVATE",
  "admin_resource_entity_public_issues": "Signalements publiques",
  "admin_resource_default_display_explanation": "Signalements citoyens en visibilité \"public\" par défaut",
  "admin_resource_private_explanation": "Si OUI, les signalements citoyens ne sont pas autorisés",
  "admin_resource_entity_public_issues_explanation": "Détermine l'affichage de signalements propres ou hérités des communes liées",
  "admin_resource_entity_public_issues_this": "Self",
  "admin_resource_entity_public_issues_slaves": "Slave",
  "admin_resource_entity_code": "Code de numérotation des fiches",
  "admin_resource_entity_code_explanation": "Code utilisé pour la numérotation des signalements",
  "admin_resource_entity_geolocation": "Géolocalisation",
  "latitude": "Latitude",
  "longitude": "Longitude",
  "admin_resource_betterstreet_url": "URL Betterstreet",
  "admin_resource_betterstreet_url_explanation": "Extension d'URL pour la page spécifique sur Betterstreet",
  "admin_resource_web_url": "URL Web externe",
  "admin_resource_web_url_explanation": "URL externe pour lien sur page des communes liées",
  "admin_resource_entity_description_label": "Préfixe emails - Nom officiel entité ou commune",
  "admin_resource_entity_description_label_explanation": "Préfixe utilisé dans les emails vers citoyens, avant \"nom\". Aussi utilisé dans les messages de confirmation d'envoi en mobile ex. la ville de  ou la communautés de commune de",
  "admin_resource_entity_colors": "Couleurs",
  "admin_resource_entity_colors_explanation": "Couleurs pour l'habillage custom de la homepage entité",
  "admin_resource_entity_colors_background": "Couleur background",
  "admin_resource_entity_colors_border": "Couleur ligne",
  "admin_resource_entity_tag_line": "Tag Line",
  "admin_resource_email_signature": "Email signature",
  "admin_resource_email_footer": "Email footer",
  "admin_resource_emails_options": "Contenu des emails",
  "admin_resource_email_signature_explanation": "Signature spécifique pour les mails vers l'extérieur",
  "add_entity": "Ajouter entité",
  "admin_resource_commune_entity_active": "Commune active au sein de l'entité",
  "admin_resource_commune_entity_active_explanation": "Commune active au sein de l'entité à laquelle est est liée (si \"Slave\")",
  "admin_resource_commune_description_label": "Préfixe emails",
  "admin_resource_commune_description_label_explanation": "Préfixe utilisé dans les emails vers citoyens, avant \"nom\". Ville de ...ou Communautés de commune de ...sert aussi dans les messages sur l'app citoyen",
  "admin_resource_email_footer_explanation": "Footer spécifique ajouté sous mails externes",
  "linked": "Lié",
  "active": "Active",
  "form_title_tag_edit": "Edit catégorie",
  "none": "Aucun",
  "admin_resource_tag_link_show": "Montrer la catégorie liée niveau commune",
  "admin_resource_private_message": "Message custom si citoyens bloqués",
  "confirmation_issue_created_to_entity": "Signalement ajouté au niveau de l'environnement en charge de cette catégorie",
  "confirmation_issue_created_to_entity_explanation": "Le signalement a été automatiquement envoyé au responsable externe de cette catégorie et n'est donc pas visible dans la liste de votre commune.",
  "admin_resource_commune_no_client_notification": "Commune non-cliente notifiée lors d'un nouveau signalement",
  "admin_resource_commune_no_client_notification_explanation": "Si non, la commune ne sera pas notifiée lors d'un nouveau signalement.",
  "admin_resource_commune_freemium": "Freemium ?",
  "admin_resource_commune_freemium_explanation": "Client Freemium avec fonctionnalités limitées",
  "no_nearby_issues": "Aucun signalement à proximité...",
  "freemium_modal_calendar_title": "Outil de gestion de plannings des équipes",
  "freemium_feature_not_available": "Cette fonctionnalité n'est pas disponible dans la version Free de BetterStreet.",
  "freemium_modal_stats_title": "Outil d'analyse statistique",
  "freemium_modal_building_title": "Gestion des interventions dans les bâtiments communaux",
  "freemium_modal_map_title": "Visualisation géographique des interventions",
  "freemium_modal_admin_title": "Gestion et personnalisation des paramètres de l'application (utilisateurs, catégories, équipes,...)",
  "freemium_modal_recurrence_title": "Gestion des interventions récurrentes",
  "freemium_modal_issues_list_print_title": "Impression de la liste de signalements",
  "freemium_modal_csv_title": "Export de la liste de signalements au format CSV (Excel)",
  "freemium_modal_issue_print_title": "Impression du signalement et du bon de travail",
  "freemium_modal_work_order_title": "Planification et impression du bon de travail",
  "freemium_modal_internal_message_title": "Envoi de messages internes",
  "freemium_modal_transfer_title": "Transfert du signalement vers un tiers",
  "email_reports_is_owner": "Uniquement pour les signalements dont vous êtes gestionnaire",
  "for": "pour",
  "create": "Créer",
  "deleteIssueDeadline": "Date d'échéance supprimée",
  "updateIssueDeadline": "Date d'échéance modifiée",
  "issues_sort_created": "Les plus récents",
  "issues_sort_updated": "Mis à jour",
  "issues_sort_scheduled": "Par échéances",
  "smart_home_new_issues": "Derniers signalements",
  "smart_home_deadline_this_week": "Arrive bientôt à échéance",
  "smart_home_planned_today": "Planifié dans les prochains jours",
  "smart_home_deadline_outdated": "Echéances dépassées",
  "smart_home_last_activities": "Dernières activités constatées (à l'exception de vos activités)",
  "smart_home_settings": "Personnaliser ma page d'accueil",
  "smart_home_settings_my_issues": "Voir seulement les entrées pour lesquelles je suis gestionnaire",
  "smart_home_settings_owners_issues": "Voir les interventions des gestionnaires suivants",
  "apply": "Appliquer",
  "print_work_orders": "Bons de travail - Un par page",
  "work_order_done": "Fait",
  "work_order_started": "Démarré",
  "work_order_resolved_date": "Date de réalisation",
  "work_order_performer": "Réalisé par",
  "work_order_time": "Temps passé",
  "hours": "Heures",
  "work_order_note": "Commentaires",
  "yesterday": "Hier",
  "public_space_and_building": "Espace public & Bâtiments",
  "freemium_modal_priority_title": "freemium_modal_priority_title",
  "freemium_modal_public_comment_title": "freemium_modal_public_comment_title",
  "printed_on": "Imprimé le",
  "printWorkOrder": "Bon de travail imprimé",
  "smart_home_weather": "Météo",
  "scheduled_from": "Planifié du",
  "creator": "Créé par",
  "printGroupedWorkOrder": "Bon de travail imprimé (groupé)",
  "add_quick_entry": "Ajout entrée rapide",
  "citizen_by_bs": "Citoyen via BetterStreet",
  "calendar_print_error_no_issues": "Oops. Il n'y aucune intervention à imprimer.",
  "see_calendar": "Voir calendrier",
  "updatePlanification": "Planification modifiée",
  "contactIssueRequestorAndFollowers": "Message envoyé à",
  "contact_by_email": "Contacter via email",
  "followers": "suiveurs",
  "follower": "suiveur",
  "issue_contact_missing": "Oops. Vous devez sélectionner au moins un contact",
  "issueFollowed": "Nouveau suiveur citoyen",
  "issueUnfollowed": "Suivi annulé par un citoyen",
  "nav_smart_home": "Page d'accueil",
  "print_work_order_select_user": "pour les équipes suivantes",
  "delete_issue_picture": "Supprimer",
  "code": "code",
  "addIssuePictureFromCitizen": "Photo ajoutée par un citoyen",
  "entities": "Entités",
  "district": "district",
  "admin_resource_entity_bounds": "admin_resource_entity_bounds",
  "bounds": "bounds",
  "add_company": "add_company",
  "is_admin": "is_admin",
  "new_issue_slaves_attribution_failed_no_entity_found": "Nous n'avons pu attribuer le signalement à une entité.",
  "new_issue_slaves_attribution_failed_multiple_entities_found": "Nous n'avons pu attribuer le signalement à une entité.",
  "issue": "Signalement",
  "admin_resource_commune_bounds": "admin_resource_commune_bounds",
  "pictures": "Photos",
  "firstName": "Prénom",
  "lastName": "Nom",
  "addIssueInternalNoteByTransfer": "Message provenant d'un signalement transféré",
  "add_email": "Ajouter un email",
  "send_me_a_copy": "M'envoyer une copie de l'email envoyé",
  "stats_access": "Accès au module statistique",
  "user_pro_stats_access_explanation": "Suivre les statistiques",
  "are_you_government": "Vous êtes une commune",
  "commune_invite_1": "Vous voulez recevoir cette information à un autre email?",
  "commune_invite_2": "Vous voulez en savoir plus sur la version Premium de BetterStreet et avoir une démonstration sans engagement.",
  "contact_us": "Contactez-nous",
  "error_district_attribution": "Oops. Une erreur est survenue.",
  "error_district_attribution_explanation": "Pour une raison inconnue, nous n'avons pas pu rattacher cette localisation à un district existant. Merci de signaler ce problème à support@betterstreet.org",
  "admin_resource_entity_identifier": "admin_resource_entity_identifier",
  "admin_resource_entity_identifier_explanation": "admin_resource_entity_identifier_explanation",
  "upload_csv": "upload_csv_",
  "admin_title_buildings_upload_csv": "admin_title_buildings_upload_csv",
  "admin_input_upload_csv": "admin_input_upload_csv",
  "admin_upload_csv_info": "admin_upload_csv_info",
  "create_buildings_from_csv": "Créer les bâtiments",
  "building_csv_google_map_no_results": "building_csv_google_map_no_results",
  "building_csv_existing_building_name": "building_csv_existing_building_name",
  "attribution_engine_spw-districts": "attribution_engine_spw-districts",
  "admin_entity_is_matser": "Cette entité chapeaute t'elle d'autres entités?",
  "login_error_user_not_found": "L'email ou le mot de passe introduit n'est pas valide",
  "updateIssueEtag": "Catégorie modifiée",
  "login_error_incorrect_password": "L'email ou le mot de passe introduit n'est pas valide",
  "issue_details_title_history": "Historique",
  "issue_details_title_near": "A proximité",
  "issue_details_title_requestor": "Demandeur & suiveurs",
  "issue_details_title_planification": "Planification",
  "issue_details_add_note": "Ecrire une note interne",
  "issue_details_add_user_to_note": "Ajouter destinataire",
  "please_wait": "La plus grande des vertus est bien sur la patience :)",
  "recurrence_no_limit": "Aucune limite dans le temps",
  "first_occurence_created_at": "Première occurrence créée le",
  "recurrence_issues_already_created": "Occurrence(s) déjà créée(s)",
  "recurrence_next_issues": "Prochaine(s) occurrences(s)",
  "recurrence_last_three": "trois dernières",
  "update_requestor": "Editer",
  "press_esc_to_exit": "Utilisez la touche ESC pour quitter",
  "duplicate": "Dupliquer",
  "issue_duplicate_explanation": "La localisation et les photos éventuelles seront dupliquées",
  "issue_duplicate_link_new_issue": "Lier les deux interventions",
  "issue_duplicated_links": "Interventions liées",
  "issue_duplicate_from": "dupliqué depuis",
  "issue_group_name_label": "Nom du groupe des signalements",
  "issue_will_be_added_to_group": "La nouvelle intervention sera liée au groupe",
  "unlink": "Délier",
  "link": "Lier",
  "of_the_group": "du groupe",
  "unlink_confirmation_1": "Êtes-vous certain de vouloir délier l'intervention",
  "this_issue_is_linked_to": "Cette intervention est liée à",
  "issueUnlinked": "Intervention déliée",
  "issueDuplicated": "Intervention dupliquée",
  "issueGrouped": "Nouveau lien de",
  "with": "avec",
  "issueUngrouped": "Lien supprimé entre",
  "merge": "Fusionner",
  "merge_issue_explanation_1": "Vous allez fusionner le signalement",
  "will_be_added_to": "seront ajoutés à",
  "merge_issue_pictures": "Les photos et fichiers de",
  "will_be_added_as_follower_to": "sera ajouté comme suiveur à",
  "the_requestor": "Le demandeur",
  "issueMerged": "Signalement fusionné avec",
  "reopen": "Réouvrir",
  "issue_detail_merged_close": "Fermer",
  "issueReopened": "Signalement réouvert",
  "working_hours": "Horaires de travail",
  "form_add_new_man_to_team": "Ajout d'un agent à l'équipe",
  "morning": "Matin",
  "afternoon": "Après-midi",
  "external": "Externe",
  "admin_array_title_teams": "Equipes",
  "add_man": "Ajouter un agent",
  "add_team": "Ajouter une équipe",
  "form_title_team_edit": "Modification équipe",
  "remove_user_from_team_confirmation_1": "Êtes-vous sur de vouloir désactiver",
  "remove_user_from_team_confirmation_2": "de l'équipe",
  "remove_from_team": "Retirer de l'équipe",
  "issuePlanned": "Assignation modifiée",
  "teams_and_agents": "Equipes & Agents",
  "add_leave": "Ajouter une indisponibilité",
  "add_leaves_to": "Ajouter une indisponibilité à",
  "reason": "Raison",
  "all_the_day": "Toute la journée",
  "leaves_period": "Période",
  "teams": "Équipes",
  "work_timetable": "Absent",
  "does_not_work_this_day": "ne travaille pas ce jour",
  "three_days": "3 jours",
  "admin_nav_teams": "Equipes & Agents",
  "users": "Agents",
  "assign_removed": "Suppression assignation",
  "assign_added": "Ajout assignation",
  "first_name_and_last_name_already_used": "Un agent avec le même nom existe déjà Vous ne pouvez introduire un agent qui a le même nom qu'un utilisateur existant.",
  "existing_patrimoiny_name": "Un équipement portant se nom existe déjà.",
  "admin_array_title_patrimonies": "Equipements",
  "admin_nav_patrimonies": "Véhicules & Equipements",
  "repair": "Réparation",
  "maintenance": "Entretien",
  "nears_patrimony": "Pour cet équipement",
  "issuePatrimoniesUpdated": "Equipements modifiés",
  "added": "Ajouté",
  "removed": "Supprimé",
  "patrimony": "Equipement",
  "add_patrimony": "Ajouter un équipement",
  "from_patrimony": "Choisir un équipement",
  "patrimonies": "Equipements",
  "forgot_password_error_unknow_email": "Email inconnu ou pseudo. Merci de prendre contact avec votre administrateur.",
  "update_issue_patrimony": "Choisir un autre équipement",
  "updateIssuePatrimony": "Equipement modifié",
  "session_error_expired": "Votre session utilisateur a expiré. Merci de bien vouloir vous connecter à nouveau.",
  "form_patrimony": "Choisir un équipement",
  "form_title_patrimony_creation": "Création d'un nouvel équipement",
  "select_transfer": "Ajouter un transfert",
  "select_team_or_agents": "Ajouter une assignation",
  "edit_leave": "Modifier les congés",
  "edit_leave_to": "Modifier les congés de",
  "admin_resource_public_spaces_management": "Espace publics?",
  "admin_resource_patrimonies_management": "Equipements?",
  "existing_work_timetable_leaves": "L'agent de ne travail pas pour cette période.",
  "existing_work_timetable_leaves_validation_explanation": "Des congés ne peuvent pas être attribué pour ces jours.",
  "existing_leaves": "Il y a déjà des congés attribués pour cette période.",
  "existing_leaves_validation_explanation": "Merci de modifier ou supprimer les congés existants.",
  "remove_hashtag_from_issue_confirmation": "Êtes-vous sûr de vouloir supprimer l'étiquette",
  "remove_hashtag_from_issue_confirmation_2": "du signalement",
  "removeIssueHashtag": "Étiquette retirée",
  "remove_hashtag_from_issue": "Suppression étiquette",
  "admin_resource_commune_starter_explanation": "Le lien des nouveaux signalements dans les emails envoyés à la commune dirigera vers la vue starter (possibilité de changement de statut)",
  "admin_resource_commune_starter": "BetterStreet Starter",
  "issue_stater_access_not_authorized": "L'accès à ce signalement n'est pas autorisé",
  "starter_states_update": "Cliquer sur un statut afin de mettre à jour le signalement et informer le demandeur",
  "starter_states_update_explanation": "Informer le demandeur du traitement",
  "add_hashtag": "Etiquette",
  "add_hashtag_to_issue": "Etiquette",
  "add_a_hashtag_placeholder": "Ajouter une étiquette",
  "most_used_hashtags": "Etiquettes les plus utilisées",
  "please_wait_csv_in_preparation": "Fichier CSV en préparation, merci de patienter",
  "it_can_take_up_to_a_few_minutes": "Cela peut prendre jusqu'à quelques minutes",
  "learn_more": "En savoir plus",
  "starter_discover_betterstreet": "Découvrez BetterStreet Expert, la solution de gestion des interventions pour services techniques",
  "copy_url_link": "Copier le lien",
  "no_patrimonies_message": "Un équipement est un véhicule, un gros outillage ou tout autre équipement nécessaire à la réalisation d'une tâche. La gestion des équipements se fait via la console d'administration.",
  "no_patrimonies_link_to_console": "Créer votre premier équipement via la console d'administration",
  "no_teams_link_to_console": "Créer votre première équipe via la console d'administration",
  "no_teams_message": "Equipe & Agents est utile afin d'identifier à qui vous confier la réalisation d'une tâche. Un agent fait toujours partie d'une équipe.",
  "issue_starter_confirmation_state_updated": "Votre email a bien été envoyé",
  "addIssueHashtags": "Etiquette(s) ajoutée(s)",
  "removeIssueHashtags": "Etiquette(s) retirée(s)",
  "man": "agent",
  "mans": "agents",
  "please_wait_1": "A la patience, on reconnait l'homme...",
  "please_wait_2": "Les meilleures choses ont besoin de patience...",
  "please_wait_3": "La patience est un arbre dont la racine est amère, et dont les fruits sont très doux...",
  "please_wait_4": "La patience est la clé du bien-être.",
  "please_wait_5": "Cette seconde d'attente vous est offerte par l'équipe BetterStreet.",
  "add_existing_user": "Ajouter un utilisateur existant",
  "invite": "Inviter",
  "rgpd_your_issues_and_actions": "Vos signalements et commentaires",
  "rgpd_your_personal_data": "Vos données personnelles",
  "rgpd_registration_device": "Appareil enregistré",
  "rgpd_registration_provider": "Origine",
  "rgpd_issue_created_from": "Origine",
  "my_issues_lists": "Mes listes",
  "remove_issues_list_confirmation": "Etes-vous sur de vouloir supprimer cette liste?",
  "selected_filters": "Filtres sélectionnés",
  "new_issues_lists": "Nouvelle liste",
  "issues_lists_set_default": "Faire de cette liste ma liste par défaut",
  "issues_list_existing_preset": "Cette configuration de filtres existe déjà",
  "issues_lists_impossible_to_change_values_of_this_preset": "Il est impossible de modifier la configuration des filtres de cette liste",
  "issues_list_missing_label": "Merci d'ajouter un nom",
  "issues_list_existing_label": "Ce nom est déjà utilisé",
  "issues_list_no_filters_setted": "Vous devez d'abord filtrer votre liste avant de pouvoir enregistrer une nouvelle liste.",
  "notifications_mark_all_as_read": "Tout marquer comme lu",
  "notifications_settings": "Paramètres de notifications",
  "notifications": "Notifications",
  "settings": "Paramètres",
  "issue_details_title_internal_followers": "Suiveurs internes",
  "follow_this_issue": "Suivre",
  "issueInternalUnFollow": "Suivi interne supprimé",
  "issueInternalFollow": "Nouveau suivi interne",
  "unfollow_this_issue": "Ne plus suivre",
  "CSV": "CSV",
  "issue_actions": "Actions",
  "notifications_settings_explanation": "Une notification vous est adressée par email ou via une alerte dans BetterStreet lorsqu'un changement qui pourrait vous intéresser se produit.",
  "notifications_settings_as_owner_title": "Pour les interventions dont je suis gestionnaire",
  "notifications_settings_new_issue": "Lorsqu'un nouveau signalement est introduit",
  "notifications_settings_citizen_message": "Nouveau message d'un citoyen sur un signalement",
  "notifications_settings_new_internal_comment": "Nouveau commentaire introduit",
  "notifications_settings_update_issue_state": "A chaque modification de statut",
  "notifications_settings_update_issue": "Pour toute autre modification",
  "notifications_settings_as_follower_title": "Pour les interventions que je suis",
  "notifications_settings_global_title": "Pour toute intervention",
  "notifications_settings_internal_message": "Nouveau message qui m'est adressé",
  "notification": "Notification",
  "notifications_settings_validation_select_one": "Merci de choisir au moins un canal pour cette notification",
  "user_issue_settings_default_follow_as_creator": "Suivre par défaut les signalements que je crée",
  "notification_mark_as_read": "Marquer comme lu",
  "followed": "Suivi",
  "not_followed": "Non suivi",
  "notifications_empty_list": "Aucune notification",
  "selected_agents": "Agents sélectionnés",
  "work_orders": "Bons de travail",
  "selected_issues": "fiches sélectionnées",
  "valorizations_options": "Paramètres de valorisation",
  "default_agents_hourly_cost": "Coûts TTC en euros  par défaut d'un agent",
  "default_vat": "Pourcentage TVA",
  "hourly_cost": "Coût horaire en euros ttc",
  "admin_resource_valorization": "Module de valorisation des prestations",
  "valorization_validation_no_selected_quantities": "Aucune quantité n'est attribuée",
  "addIssueValorizaiton": "Valorisation ajoutée",
  "updateIssueValorizaiton": "Valorisation modifiée",
  "deleteIssueValorizaiton": "Valorisation suprimée",
  "new_valorization": "Nouvelle valorisation",
  "valorization_modal_subtitle": "(Seuls les éléments dont le coût est enregistré)",
  "add_valorization": "Valorisation",
  "delete_valorization_confirmation": "Etes-vous sur de vouloir supprimer cette valorisation?",
  "quantity": "Quantité",
  "cost_by_unit": "Coûts TTC par unité en euros",
  "total_cost": "Coût total",
  "valorization": "Valorisation",
  "valorization_date": "Date de la valorisation",
  "supplies": "Fournitures",
  "addIssueSupply": "Fourniture ajoutée",
  "supply": "Fourniture",
  "removeIssueSupply": "Fourniture supprimée",
  "total": "Total",
  "add_supply_to_issue": "Ajouter une fourniture",
  "external_reference": "Référence externe",
  "valorization_unit": "Unité",
  "add_external_service_to_issue": "Ajouter une prestation externe",
  "patrimonies_already_used_by": "Attention: cet équipement est déjà utilisé à cette date pour l'intervention",
  "click_here_to_select_files": "Cliquez ici pour choisir un fichier",
  "transfer_auto_change_state": "Changer le statut à transféré",
  "services": "Prestations externes",
  "cost_excl_tax": "Coûts HT en euros",
  "vat": "Pourcentage TVA",
  "new_supply": "Créer fourniture",
  "no_supplies_match_filters": "Aucune fourniture trouvée",
  "patrimony_valorization_type": "Unités de mesure",
  "patrimony_valorization_cost": "Coût TTC en euros par unité de mesure",
  "valorization_option_hourly": "Heures",
  "valorization_option_km": "Kilomètres",
  "issues_map_adapt_list": "Adapter la liste à la zone visible",
  "search_by_bs_id": "Chercher par numéro de fiche",
  "merge_search_issue_explanation": "Chercher l'intervention à fusionner en tapant son numéro",
  "selector_error_select_at_least_one": "Aucune intervention sélectionnée!",
  "teller_profile": "Guichet",
  "link_issue": "Lier deux interventions",
  "link_search_issue_explanation": "Chercher l'intervention à lier en tapant son numéro",
  "issues_link_already_in_same_group": "Ces interventions sont déjà liées entre elles",
  "issues_link_already_in_other_group": "Déjà liée à d'autres interventions",
  "update_issues_state_set_public": "Publier les signalements (uniquement pour Espace public)",
  "building_external_link": "Lien cartographique externe",
  "external_link": "Lien cartographique externe",
  "update_issues_state_contact_requestors": "Avertir les demandeurs par email / notification",
  "link_issue_explanation_1": "Vous allez lier la fiche",
  "new": "Nouveau",
  "planification": "Planification",
  "back_to_console": "Retour à la console d'administration",
  "selector_error_too_many_selected_issues": "Vous avez sélectionné trop de fiches d'un coup. Les actions peuvent être groupées sur un maximum de 50 fiches à la fois.",
  "grouped_planification": "Planification groupée",
  "csv_valorization_agents": "Coût agents",
  "csv_valorization_patrimonies": "Coût équipements",
  "csv_valorization_supplies": "Coût fournitures",
  "csv_valorization_external_service": "Coût prest. ext.",
  "csv_valorization_total": "Coût total",
  "cost_tax": "Coûts TTC en euros",
  "stats_patrimony_tags_bar": "Véhicules & Equipements - Catégories les plus utilisées",
  "hashtags": "Etiquettes",
  "notifications_settings_reports_title": "Rapports statistiques",
  "optional": "Optionnel",
  "cost_not_defined": "Non défini",
  "valorization_validation_item_witout_cost": "Un élément de valorisation n'a pas de coût! Merci de retirer cet élément ou de lui attribuer un coût via la console d'administration",
  "stats_valorizations_by_date": "Valorisation par date",
  "stats_valorizations_kinds_pie": "Valorisation par type",
  "stats_valorizations_by_tags": "Valorisation par catégorie",
  "cost": "Coût",
  "valorizations_options_apply_to_teams": "Appliquer partout",
  "valorizations_options_apply_to_teams_explanation": "Vous allez mettre à jour le coût pour toutes vos équipes et agents",
  "valorizations_options_apply_to_teams_erase_existant": "Ecraser aussi les valeurs existantes",
  "error_404_issue_not_found": "Oops. Signalement inexistant ou accès refusé.",
  "update_picture": "Modifier la photo",
  "reset": "Réinitialiser",
  "cancel_changes": "Annuler les changements",
  "add_first_name": "Ajouter le prénom",
  "add_last_name": "Ajouter le nom",
  "user_profile_settings": "Mon profil",
  "user_identifier_type": "Email ou Pseudo",
  "access_to_bs": "Accès à BetterStreet",
  "nickname": "Pseudo",
  "received_the_invitation": "a reçu l'invitation par email",
  "disabled_user_pro": "Utilisateur désactivé",
  "email_or_nickname": "Email ou pseudo",
  "form_validation_nickname": "Pseudo non valide (Minimum 5 caractères alphanumériques)",
  "agent_profile": "Agent",
  "drag_one_picture": "Glisser ici une photo ou un pdf",
  "activate_your_userpro_account": "Activation de votre compte BetterStreet",
  "finished": "Terminé",
  "ongoing": "En cours",
  "to_do": "A faire",
  "workorder_update_state_title": "Modification du statut du bon de travail",
  "updateIssueWorkorderState": "Statut du bon de travail modifié",
  "work_orders_outdated_but_not_closed": "En retard / Non terminés",
  "confirmation_issue_created_by_agent_explanation": "",
  "confirmation_issue_created_by_agent": "Nouvelle fiche bien enregistrée",
  "workOrder_update_state_title": "Confirmation changement statut bon de travail",
  "agent_displayed_days": "#jours planning visibles",
  "no_access": "Pas d'accès",
  "disbaled": "Désactivé",
  "password_modified_for": "Mot de passe modifié pour",
  "password_saved": "Mot de passe sauvé. Merci d'avertir l'utilisateur.",
  "display_password": "Afficher le mdp",
  "default_agents_displayed_days": "Nombre de jours de planning visible pour un agent",
  "agents_resolved_issues_on_finish": "Mettre à résolu automatiquement un bon de travail clôturé par un agent ",
  "agents_display_issues_assigned_to_team": "Les bons de travail non attribués individuellement sont visibles par tous les agents de l'équipe",
  "agents_options": "Paramètres des bons de travail",
  "disabled": "Désactivé",
  "reset_form_modal_title": "Confirmation",
  "reset_form_modal_message": "Etes-vous sur?",
  "confirm": "Confirmer",
  "stats_patrimonies_bar": "Equipements les plus actifs",
  "form_validation_invalid_link": "",
  "period_allday": "Du %start% au %end%",
  "period_sameday_allday": "Le %date%",
  "period_sameday_withhour": "Le %date% de %start% à %end%",
  "period_withhour": "Du %start_date% à %start_hour% au %end_date% à %end_hour%",
  "warningAbsenceModal_plannedOnIndisponibility": "Planification sur période d'indisponibilité",
  "warningAbsenceModal_questionIndisponibilityOnPlanned": "L'agent %username% est indisponible sur cette période, souhaitez vous tout de même procéder à la planification de l'intervention ?",
  "copy_calendar_link": "Copier le lien Ical pour paramétrer mon calendrier",
  "equipments": "Équipements",
  "select_supply_search": "Chercher...",
  "calendar_link_copied_successful": "Le lien a été copié avec succès. Pour plus d'informations sur son implémentation, merci de vous référer à la",
  "calendar_link_documentation": "documentation",
  "planning_calendar_link_copied_successful": "Le lien a été copié avec succès. Pour plus d'informations sur son implémentation, merci de vous référer à la documentation.",
  "permanent_delete": "Suppression définitive" 
};
