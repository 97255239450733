// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-screen-size-message-container{

  width: 100%;
  background-color: rgba(255, 165, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}


.bs-screen-size-marquee {
  white-space: nowrap; 
  overflow: hidden;
  width: 100%;
  height: fit-content;
}


.bs-screen-size-message{
  display: inline-block;
  color: var(--std-color-orange);
  font-size: 24px;
  font-weight: bold;
  animation: scroll 6s linear infinite; 
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/screenSizeMessage.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,wCAAwC;EACxC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;;AAGA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,mBAAmB;AACrB;;;AAGA;EACE,qBAAqB;EACrB,8BAA8B;EAC9B,eAAe;EACf,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".bs-screen-size-message-container{\n\n  width: 100%;\n  background-color: rgba(255, 165, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden; \n}\n\n\n.bs-screen-size-marquee {\n  white-space: nowrap; \n  overflow: hidden;\n  width: 100%;\n  height: fit-content;\n}\n\n\n.bs-screen-size-message{\n  display: inline-block;\n  color: var(--std-color-orange);\n  font-size: 24px;\n  font-weight: bold;\n  animation: scroll 6s linear infinite; \n}\n\n@keyframes scroll {\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(-100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
